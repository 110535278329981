<main>
  <div
    class="grid items-center justify-center w-screen h-screen bg-cover place-items-center bg-mycolor"
  >
    <div
      class="flex flex-col p-5 bg-mywhite rounded-xl bg-opacity-60 backdrop-filter backdrop-blur-lg"
    >
      <div class="flex flex-col w-full md:flex-row ">
        <div class="w-3/4">
          <div class="flex justify-between font-semibold header-card">
            <div class="text-mycolor">MohammadReza Rahmatzadeh</div>
          </div>
          <div class="font-sans">
            <div class="text-mycolor">> Software Engineer</div>
          </div>
        </div>

        <div class="relative order-first w-1/4 place-self-center md:order-last">
          <img
            class="w-full h-full border-2 border-solid rounded-full shadow-sm border-mycolor"
            src="/MohammadRezaRahmatzadeh.jpeg"
            alt="MohammadReza Rahmatzadeh"
          />
        </div>
      </div>
      <div class="flex flex-col w-full md:flex-row ">
        <div class="w-full">
          <div class="flex justify-between font-semibold header-card">
            <div class="text-mycolor">Preferred Technology Stack</div>
          </div>
          <div class="flex flex-col font-sans">
            <div class="text-mycolor">> Backend</div>
            <ul class="ml-10 list-disc">
              <li>.NET 6 and above</li>
              <li>NodeJS</li>
              <li>Rust</li>
              <li>Python</li>
            </ul>
          </div>
          <div class="font-sans">
            <div class="text-mycolor">> Database</div>
            <ul class="ml-10 list-disc">
              <li>PostgreSQL</li>
              <li>SQL Server</li>
              <li>Elasticsearch</li>
              <li>Redis</li>
            </ul>
          </div>
          <div class="font-sans">
            <div class="text-mycolor">> Front-end</div>
            <ul class="ml-10 list-disc">
              <li>Svelte</li>
              <li>React</li>
              <li>Blazor</li>
            </ul>
          </div>
          <div class="font-sans">
            <div class="text-mycolor">> Server</div>
            <ul class="ml-10 list-disc">
              <li>Ubuntu</li>
            </ul>
          </div>
          <div class="font-sans">
            <div class="text-mycolor">> DevOps Tools</div>
            <ul class="ml-10 list-disc">
              <li>Jira Software</li>
              <li>Git, Gitlab, Github</li>
              <li>Docker</li>
              <li>Kubernetes</li>
            </ul>
          </div>
        </div>
      </div>

      <br />
      <div class="flex flex-col w-full md:flex-row ">
        <div class="w-full">
          <div class="flex justify-between font-semibold header-card">
            <div class="text-mycolor">Contact</div>
          </div>

          <div class="flex flex-row space-x-3">
            <a
              href="https://www.linkedin.com/in/mohammadreza-rahmatzadeh/"
              target="_blank"
            >
              <svg
                class="w-6 h-6 fill-current text-mycolor"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 448 512"
              >
                <path
                  d="M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 0 1 107.58 0c0 29.7-24.1 54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3 94 0 111.28 61.9 111.28 142.3V448z"
                />
              </svg>
            </a>

            <a href="https://telegram.me/marshalofficial" target="_blank">
              <svg
                class="w-6 h-6 fill-current text-mycolor"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 496 512"
              >
                <path
                  d="M248,8C111.033,8,0,119.033,0,256S111.033,504,248,504,496,392.967,496,256,384.967,8,248,8ZM362.952,176.66c-3.732,39.215-19.881,134.378-28.1,178.3-3.476,18.584-10.322,24.816-16.948,25.425-14.4,1.326-25.338-9.517-39.287-18.661-21.827-14.308-34.158-23.215-55.346-37.177-24.485-16.135-8.612-25,5.342-39.5,3.652-3.793,67.107-61.51,68.335-66.746.153-.655.3-3.1-1.154-4.384s-3.59-.849-5.135-.5q-3.283.746-104.608,69.142-14.845,10.194-26.894,9.934c-8.855-.191-25.888-5.006-38.551-9.123-15.531-5.048-27.875-7.717-26.8-16.291q.84-6.7,18.45-13.7,108.446-47.248,144.628-62.3c68.872-28.647,83.183-33.623,92.511-33.789,2.052-.034,6.639.474,9.61,2.885a10.452,10.452,0,0,1,3.53,6.716A43.765,43.765,0,0,1,362.952,176.66Z"
                />
              </svg>
            </a>

            <a href="https://github.com/MarshalOfficial" target="_blank">
              <svg class="w-6 h-6 fill-current text-mycolor" viewBox="0 0 16 16"
                ><path
                  fill-rule="evenodd"
                  d="M8 0C3.58 0 0 3.58 0 8c0 3.54 2.29 6.53 5.47 7.59.4.07.55-.17.55-.38 0-.19-.01-.82-.01-1.49-2.01.37-2.53-.49-2.69-.94-.09-.23-.48-.94-.82-1.13-.28-.15-.68-.52-.01-.53.63-.01 1.08.58 1.23.82.72 1.21 1.87.87 2.33.66.07-.52.28-.87.51-1.07-1.78-.2-3.64-.89-3.64-3.95 0-.87.31-1.59.82-2.15-.08-.2-.36-1.02.08-2.12 0 0 .67-.21 2.2.82.64-.18 1.32-.27 2-.27.68 0 1.36.09 2 .27 1.53-1.04 2.2-.82 2.2-.82.44 1.1.16 1.92.08 2.12.51.56.82 1.27.82 2.15 0 3.07-1.87 3.75-3.65 3.95.29.25.54.73.54 1.48 0 1.07-.01 1.93-.01 2.2 0 .21.15.46.55.38A8.013 8.013 0 0 0 16 8c0-4.42-3.58-8-8-8z"
                />
              </svg>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</main>
